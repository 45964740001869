import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { DefaultDataServiceConfig } from '@ngrx/data';
import { Observable } from 'rxjs';

import { FlashyAccount } from 'account/domain';

@Injectable({
  providedIn: 'root',
})
export class AccountDataService {
  private httpClient = inject(HttpClient);
  private url = inject(DefaultDataServiceConfig);

  getAccounts(): Observable<{
    data: FlashyAccount[];
  }> {
    return this.httpClient.get<{
      data: FlashyAccount[];
    }>(`
${this.url.root}/accounts`);
  }

  update(payload: Partial<FlashyAccount>): Observable<{
    data: FlashyAccount;
  }> {
    return this.httpClient.put<{
      data: FlashyAccount;
    }>(
      `
${this.url.root}/account/${payload.account}`,
      payload
    );
  }

  add(payload: Partial<FlashyAccount>): Observable<{
    data: FlashyAccount;
  }> {
    return this.httpClient.post<{
      data: FlashyAccount;
    }>(
      `
${this.url.root}/account`,
      payload
    );
  }

  getById(id: string): Observable<{
    data: FlashyAccount;
  }> {
    return this.httpClient.get<{
      data: FlashyAccount;
    }>(`
${this.url.root}/account/${id}`);
  }
}
