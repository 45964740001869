import { computed, inject } from '@angular/core';
import {
  patchState,
  signalStore,
  withComputed,
  withMethods,
  withState,
} from '@ngrx/signals';
import {
  setEntities,
  setEntity,
  updateEntity,
  withEntities,
} from '@ngrx/signals/entities';
import { firstValueFrom } from 'rxjs';

import { Brands, EFlashyStatus, FlashyAccount } from 'account/domain';

import { AccountDataService } from '../account-data.service';

type AccountStore = {
  currentAccountId: string;
  isLoading: boolean;
};

const initialState: AccountStore = {
  currentAccountId: '',
  isLoading: false,
};

export const AccountStore = signalStore(
  { providedIn: 'root' },
  withState<AccountStore>(initialState),
  withEntities<FlashyAccount>(),
  withComputed((store) => ({
    selectedAccount: computed(
      () =>
        store
          .entities()
          .find(
            (value) => value.account === store.currentAccountId()
          ) as FlashyAccount
    ),
  })),
  withComputed((store) => ({
    accountBranding: computed(() => store.selectedAccount().branding),
    accountVerified: computed(
      () => store.selectedAccount().verified === EFlashyStatus.TRUE
    ),
  })),
  withMethods((store) => {
    const service = inject(AccountDataService);
    return {
      setCurrentAccountId: (currentAccountId: string) => {
        patchState(store, {
          currentAccountId,
        });
      },
      setAccount: (account: FlashyAccount) => {
        patchState(store, setEntity(account));
        patchState(store, {
          currentAccountId: `${account.account}`,
        });
      },
      updateAccount: async (payload: FlashyAccount) => {
        const account = await firstValueFrom(service.update(payload));

        patchState(
          store,
          setEntity({
            ...store.selectedAccount(),
            ...account.data,
          })
        );
        return account;
      },
      updateBranding: async (settings: Brands): Promise<void> => {
        await firstValueFrom(
          service.update({
            ...store.selectedAccount(),
            branding: settings,
          })
        );

        patchState(
          store,
          updateEntity({
            id: store.currentAccountId(),
            changes: {
              branding: settings,
            },
          })
        );
      },
      getAccounts: async () => {
        try {
          const accounts = await firstValueFrom(service.getAccounts());
          patchState(store, setEntities(accounts.data));
        } catch (error) {
          // handle error
        }
      },
      verifyAccount: async (
        accountId = store.currentAccountId()
      ): Promise<boolean> => {
        if (accountId === store.currentAccountId() && store.accountVerified()) {
          return true;
        }

        const account = await firstValueFrom(service.getById(accountId));

        patchState(store, {
          currentAccountId: account.data.account,
        });
        return account.data.verified === EFlashyStatus.TRUE;
      },
    };
  }),
  withMethods((store) => {
    const service = inject(AccountDataService);
    return {
      addAccount: async (payload: FlashyAccount) => {
        try {
          patchState(store, {
            isLoading: true,
          });
          await firstValueFrom(service.add(payload));
          await store.getAccounts();
          patchState(store, {
            isLoading: false,
          });
        } catch (error) {
          // handle error
        }
      },
    };
  })
);
